import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import Love from '@material-ui/icons/Favorite'
import Brush from '@material-ui/icons/Brush'
import Code from '@material-ui/icons/Code'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles({
  logo: {
    height: 110,
    width: 200,
  },
  contact: {
    paddingTop: '8rem',
    paddingBottom: '3rem',
    backgroundColor: '#e7e8e9',
  },
  icon: {
    width: 50,
  },
})

const Contact: React.FC = () => {
  const classes = useStyles()
  return (
    <Grid className={classes.contact} container justify="center" spacing={4}>
      <Grid container justify="center" item xs={12}>
        <img
          src="/img/axalogo.svg"
          alt="Antiguo X Antiguo"
          className={classes.logo}
        />
      </Grid>
      <Grid item container xs={12} justify="center" spacing={4}>
        <Grid item>
          <IconButton
            aria-label="Encuéntranos en Facebook"
            title="Encuéntranos en Facebook"
            component="a"
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/antiguoxantiguo"
          >
            <img
              className={classes.icon}
              src="/img/facebook-icon.svg"
              alt="Facebook"
            />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="Encuéntranos en Instagram"
            title="Encuéntranos en Instagram"
            component="a"
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/antiguoxantiguo"
          >
            <img
              className={classes.icon}
              src="/img/instagram-icon.svg"
              alt="Instagram"
            />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="Encuéntranos en Twitter"
            title="Encuéntranos en Twitter"
            component="a"
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/antiguoxantiguo"
          >
            <img
              className={classes.icon}
              src="/img/twitter-icon.svg"
              alt="Twitter"
            />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <Typography color="textSecondary">
          Sitio hecho con{' '}
          <Love
            aria-label="amor"
            style={{ marginBottom: '-0.2em' }}
            fontSize="small"
            color="inherit"
          />{' '}
          por:
        </Typography>
        <Typography color="textSecondary">
          <Brush
            aria-label="diseño"
            style={{ marginBottom: '-0.2em' }}
            fontSize="small"
            color="inherit"
          />{' '}
          <Link
            color="inherit"
            href="https://www.linkedin.com/in/michelle-gallardo-505747185/"
            target="_blank"
            rel="noreferrer"
          >
            Michelle Gallardo
          </Link>
        </Typography>
        <Typography color="textSecondary">
          <Code
            aria-label="desarrollo"
            style={{ marginBottom: '-0.2em' }}
            fontSize="small"
            color="inherit"
          />{' '}
          <Link
            color="inherit"
            href="https://pablo.berganza.dev/es"
            target="_blank"
            rel="noreferrer"
          >
            Pablo Berganza
          </Link>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Contact
