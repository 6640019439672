import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) =>
  createStyles({
    expandContainer: {
      height: 36,
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }),
)

interface Props {
  amount: number
  date: Date
  type: 'donations' | 'spendings'
  reason?: string
}

const AmountItem: React.FC<Props> = ({ amount, date, type, reason }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const handleExpand = () => setExpanded(!expanded)
  return (
    <ListItem
      button={!!reason as true}
      onClick={handleExpand}
      aria-expanded={expanded}
      disableGutters
    >
      <Grid container>
        <Grid
          item
          xs={12}
          container
          spacing={2}
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid container>
              {type === 'donations' ? (
                <ArrowDropUp color="primary" />
              ) : (
                <ArrowDropDown color="secondary" />
              )}
              <Typography component="span" color="textPrimary">
                ${amount.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item style={{ marginLeft: 'auto' }}>
            <Typography component="span" color="textSecondary">
              {date.toLocaleDateString()}
            </Typography>
          </Grid>
          {!!reason && (
            <Grid item className={classes.expandContainer}>
              <ExpandMoreIcon
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                fontSize="small"
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Collapse in={expanded}>
            <Typography
              style={{ marginLeft: '1.4rem', marginRight: '1rem' }}
              color="textSecondary"
            >
              {reason}
            </Typography>
          </Collapse>
        </Grid>
      </Grid>
    </ListItem>
  )
}

export default AmountItem
