import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles({
  amount: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
  },
  description: {
    fontSize: '1.5rem',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  icon: {
    opacity: 0.2,
    maxHeight: 90,
    width: '100%',
  },
})

interface Props {
  description: string
  amount?: number
  icon?: string
}

const AmountCard: React.FC<Props> = ({
  icon,
  description,
  amount,
  children,
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const handleExpand = () => setExpanded(!expanded)
  return (
    <Card>
      <CardContent>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Typography className={classes.amount} component="h2">
              {typeof amount === 'number' ? (
                `$${Math.round(amount)}`
              ) : (
                <Skeleton />
              )}
            </Typography>
            <Typography className={classes.description} component="p">
              {description}
            </Typography>
          </Grid>
          {icon && (
            <Grid xs={5} item container justify="flex-end">
              <img className={classes.icon} src={icon} alt={description} />
            </Grid>
          )}
        </Grid>
      </CardContent>
      {children && (
        <>
          <Collapse in={expanded}>
            <CardContent>{children}</CardContent>
          </Collapse>
          <Divider />
          <CardActions>
            <Button
              color="secondary"
              aria-expanded={expanded}
              onClick={handleExpand}
            >
              {expanded ? 'Ocultar' : 'Expandir'}
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  )
}

export default AmountCard
