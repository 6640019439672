import ky from 'ky'

const sheetsUrl = '/api/sheets'

const defaultSearchParams = {
  valueRenderOption: 'UNFORMATTED_VALUE',
  dateTimeRenderOption: 'FORMATTED_STRING',
}

export const fetcher = <T>(query: string, extraParams?: URLSearchParams) => {
  const queryString = new URLSearchParams(defaultSearchParams)
  if (extraParams)
    extraParams.forEach((value, key) => queryString.append(key, value))
  return ky(sheetsUrl, {
    searchParams: {
      api: `${query}?${queryString}`,
    },
  }).json<T>()
}
