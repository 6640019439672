import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from 'src/theme'
import CssBaseline from '@material-ui/core/CssBaseline'
import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.getElementById('root'),
)
