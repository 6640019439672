import React, { useMemo } from 'react'
import AmountCard from 'src/components/AmountCard'
import { fetcher } from 'src/api'
import useSWR from 'swr'
import {
  SheetsGetResponse,
  Spending,
  SheetsBatchGetResponse,
} from 'src/interfaces'
import AmountList from 'src/components/AmountList'

const ranges = [
  'Control de Gastos!B2:D',
  'Control de documentos de compra!C2:E',
]

const params = new URLSearchParams(ranges.map((value) => ['ranges', value]))

function formatData(data: SheetsGetResponse): Spending[] {
  return data.values.map(([spent_at, reason, amount]) => {
    return {
      reason,
      amount,
      spent_at: new Date(spent_at),
    }
  })
}

function formatBatch(data: SheetsBatchGetResponse): Spending[] {
  return data.valueRanges.flatMap(formatData)
}

const Spendings: React.FC = () => {
  const { data } = useSWR<SheetsBatchGetResponse>(
    ['values:batchGet', params],
    fetcher,
  )
  const spendings = useMemo(() => data && formatBatch(data), [data])
  return (
    <AmountCard
      amount={spendings?.reduce((sum, val) => sum + val.amount, 0)}
      icon="/img/spendings-icon-bw.svg"
      description="Utilizado"
    >
      <AmountList
        amounts={spendings?.sort((a, b) => +b.spent_at - +a.spent_at)}
      />
    </AmountCard>
  )
}

export default Spendings
