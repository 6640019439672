import Grid from '@material-ui/core/Grid'
import React from 'react'
import Header from 'src/components/Header'
import Metrics from 'src/components/Metrics'
import 'whatwg-fetch'
import Home from 'src/components/Home'
import HelpCenters from 'src/components/HelpCenters'
import ScrollTopButton from 'src/components/ScrollTopButton'
import Contact from 'src/components/Contact'
import BankDetails from 'src/components/BankDetails'

const App: React.FC = () => {
  return (
    <div id="home" className="App">
      <Header />
      <Grid container justify="center">
        <Grid item xs={12}>
          <Home />
        </Grid>
        <Grid id="metrics" item xs={12} style={{ overflow: 'hidden' }}>
          <Metrics />
        </Grid>
        <Grid id="helpcenters" style={{ overflow: 'hidden' }} item xs={12}>
          <HelpCenters />
        </Grid>
        <Grid id="bankaccounts" style={{ overflow: 'hidden' }} item xs={12}>
          <BankDetails />
        </Grid>
        <Grid id="contact" style={{ overflow: 'hidden' }} item xs={12}>
          <Contact />
        </Grid>
      </Grid>
      <ScrollTopButton />
    </div>
  )
}

export default App
