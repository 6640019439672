import React from 'react'
import Grid from '@material-ui/core/Grid'
import Donations from 'src/components/Donations'
import Spendings from 'src/components/Spendings'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) =>
  createStyles({
    metrics: {
      backgroundColor: theme.palette.primary.main,
      paddingTop: '4rem',
      paddingBottom: '4rem',
      paddingLeft: '0.6rem',
      paddingRight: '0.6rem',
    },
    description: {
      textAlign: 'center',
    },
    descriptionText: {
      paddingBottom: '3rem',
      color: 'white',
    },
  }),
)

const Metrics: React.FC = () => {
  const classes = useStyles()
  return (
    <Grid className={classes.metrics} container spacing={2} justify="center">
      <Grid item xs={10} className={classes.description}>
        <Typography
          variant="h5"
          component="h2"
          className={classes.descriptionText}
        >
          <strong>ACXAC</strong> es un movimiento de la sociedad civil con el
          objetivo de paliar las necesidades de las comunidades más vulnerables
          de nuestro municipio <strong>Antiguo Cuscatlán</strong>.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={5} lg={3}>
        <Donations />
      </Grid>
      <Grid item xs={12} sm={6} md={5} lg={3}>
        <Spendings />
      </Grid>
    </Grid>
  )
}

export default Metrics
