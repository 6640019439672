import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useState } from 'react'
import NavDrawer from 'src/components/NavDrawer'
import Hidden from '@material-ui/core/Hidden'
import smoothScroll from 'src/smoothscroll'

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      width: '100vw',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '0.5rem',
      [theme.breakpoints.up('sm')]: {
        width: '90vw',
      },
    },
    appBar: {
      backgroundColor: theme.palette.background.default,
    },
    navLink: {
      fontWeight: 'bold',
      fontSize: '1.2rem',
    },
  }),
)

const DesktopNav: React.FC = () => (
  <Grid container alignItems="center" spacing={4}>
    <Grid item>
      <img
        src="/img/axalogo.svg"
        style={{ width: 50, marginTop: 8 }}
        alt="Antiguo X Antiguo"
      />
    </Grid>
    <Grid item>
      <Button href="#metrics" onClick={smoothScroll('metrics')}>
        Métricas
      </Button>
    </Grid>
    <Grid item>
      <Button href="#helpcenters" onClick={smoothScroll('helpcenters')}>
        Centros de acopio
      </Button>
    </Grid>
    <Grid item>
      <Button href="#contact" onClick={smoothScroll('contact')}>
        Contacto
      </Button>
    </Grid>
  </Grid>
)

const MobileNav: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)
  return (
    <>
      <NavDrawer open={isOpen} onClose={onClose} />
      <Grid container alignItems="center">
        <Grid item>
          <IconButton onClick={onOpen} aria-label="Abrir menú">
            <MenuIcon color="action" />
          </IconButton>
        </Grid>
        <Grid item>
          <img
            src="/img/axalogo.svg"
            style={{ width: 40, marginTop: 8, marginLeft: '1rem' }}
            alt="Antiguo X Antiguo"
          />
        </Grid>
      </Grid>
    </>
  )
}

const Header: React.FC = () => {
  const classes = useStyles()
  return (
    <AppBar className={classes.appBar} position="static" elevation={0}>
      <Toolbar variant="dense" disableGutters>
        <Grid
          container
          className={classes.header}
          justify="space-between"
          alignItems="center"
          component="nav"
        >
          <Grid item xs>
            <Hidden mdUp>
              <MobileNav />
            </Hidden>
            <Hidden smDown>
              <DesktopNav />
            </Hidden>
          </Grid>
          <Grid item>
            <Button
              href="#bankaccounts"
              variant="contained"
              color="secondary"
              onClick={smoothScroll('bankaccounts')}
            >
              ¿Cómo ayudar?
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default Header
