import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  home: {
    maxWidth: 1100,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  image: {
    width: '100%',
  },
})

const Home: React.FC = () => {
  const classes = useStyles()
  return (
    <Grid className={classes.home} direction="column" container>
      <img
        className={classes.image}
        src="/img/esmomento.svg"
        alt="Es momento de actuar"
      />
      <img
        className={classes.image}
        src="/img/hands.svg"
        alt="#AntiguoEnAccion"
      />
    </Grid>
  )
}

export default Home
