import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) =>
  createStyles({
    helpCenters: {
      padding: '3rem',
    },
    map: {
      width: '100%',
    },
    heading: {
      fontWeight: 'bold',
      fontSize: '2rem',
      color: 'white',
      [theme.breakpoints.up('sm')]: {
        fontSize: '3.5rem',
      },
    },
    headingContainer: {
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
      borderRadius: 10,
      paddingLeft: '2rem',
      paddingRight: '2rem',
      textAlign: 'center',
      display: 'inline-block',
    },
    cardHeading: {
      fontWeight: 'bold',
    },
    schedule: {
      minWidth: 230,
    },
    closeButton: {
      position: 'absolute',
      top: '1rem',
      right: '1rem',
      color: 'black',
      fontWeight: 'bold',
    },
  }),
)

const CenterCard: React.FC<{ img: string; name: string; address: string }> = ({
  img,
  name,
  address,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()
  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)
  return (
    <>
      <Dialog
        aria-labelledby={name}
        aria-describedby={`${name}-address`}
        scroll="body"
        open={isOpen}
        onClose={onClose}
      >
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <img src={img} alt={name} className={classes.map} />
      </Dialog>
      <Card>
        <CardActionArea onClick={onOpen}>
          <CardMedia>
            <img src={img} alt={name} className={classes.map} />
          </CardMedia>
        </CardActionArea>
        <CardContent>
          <Typography
            id={name}
            className={classes.cardHeading}
            color="textPrimary"
            variant="h4"
            component="h3"
            gutterBottom
          >
            {name}
          </Typography>
          <Typography id={`${name}-address`} color="textSecondary">
            {address}
          </Typography>
        </CardContent>
      </Card>
    </>
  )
}

const HelpCenters: React.FC = () => {
  const classes = useStyles()
  return (
    <Grid
      className={classes.helpCenters}
      container
      justify="center"
      spacing={6}
    >
      <Grid xs={10} item>
        <div className={classes.headingContainer}>
          <Typography className={classes.heading} component="h2">
            Centros de acopio
          </Typography>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        wrap="wrap-reverse"
        spacing={2}
        container
        justify="center"
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <CenterCard
            img="/img/newschool.png"
            name="Newschool"
            address="Calle #2, Casa 15B. Colonia Lomas de San Francisco, Antiguo Cuscatlán"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <CenterCard
            img="/img/brillkids.png"
            name="Kinder BrillKidz"
            address="Final Walter Thilo Deiniger, Vivero Café el Arco. Contiguo al Palacio Municipal de Antiguo Cuscatlán"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={2}>
          <Card className={classes.schedule}>
            <CardContent>
              <Typography color="textSecondary">Horarios</Typography>
              <Typography className={classes.cardHeading} color="textPrimary">
                9:00 A.M. a 12:00 M.D.
              </Typography>
              <Typography className={classes.cardHeading} color="textPrimary">
                2:00 P.M. a 5:00 P.M.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default HelpCenters
