import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'
import smoothScroll from 'src/smoothscroll'

const useStyles = makeStyles((theme) =>
  createStyles({
    list: {
      width: 'auto',
      [theme.breakpoints.up('sm')]: {
        width: 250,
      },
    },
  }),
)

const NavList: React.FC<{
  handleClose: (event: React.KeyboardEvent | React.MouseEvent) => void
}> = ({ handleClose }) => {
  const classes = useStyles()
  return (
    <div role="presentation" onClick={handleClose} onKeyDown={handleClose}>
      <List className={classes.list}>
        <ListItem
          button
          component="a"
          href="#metrics"
          onClick={smoothScroll('metrics')}
        >
          <ListItemText primary="Métricas" />
        </ListItem>
        <ListItem
          button
          component="a"
          href="#helpcenters"
          onClick={smoothScroll('helpcenters')}
        >
          <ListItemText primary="Centros de acopio" />
        </ListItem>
        <ListItem
          button
          component="a"
          href="#contact"
          onClick={smoothScroll('contact')}
        >
          <ListItemText primary="Contacto" />
        </ListItem>
      </List>
    </div>
  )
}

export default function TemporaryDrawer({
  onClose,
  open,
}: {
  onClose: () => void
  open: boolean
}) {
  const handleClose = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    onClose()
  }
  return (
    <Drawer anchor={'left'} open={open} onClose={handleClose}>
      <NavList handleClose={handleClose} />
    </Drawer>
  )
}
