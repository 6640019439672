import React from 'react'
import { Donation, Spending } from 'src/interfaces'
import AmountItem from 'src/components/AmountItem'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'

interface Props {
  amounts?: (Donation | Spending)[]
}

const useStyles = makeStyles((theme) =>
  createStyles({
    list: {
      padding: 0,
      [theme.breakpoints.up('sm')]: {
        maxHeight: 300,
        overflowY: 'scroll',
        overflowX: 'hidden',
      },
    },
  }),
)

function isDonation(amount: Donation | Spending): amount is Donation {
  return !!(amount as Donation).received_at
}

const AmountList: React.FC<Props> = ({ amounts }) => {
  const classes = useStyles()
  if (!amounts) {
    return (
      <ul className={classes.list}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </ul>
    )
  }
  if (amounts.length === 0) {
    return <Typography>Todavía no hay nada aquí.</Typography>
  }
  return (
    <List className={classes.list}>
      {amounts.map((amount, index) => {
        if (isDonation(amount)) {
          return (
            <AmountItem
              key={index}
              type="donations"
              date={amount.received_at}
              amount={amount.amount}
            />
          )
        }
        return (
          <AmountItem
            key={index}
            type="spendings"
            date={amount.spent_at}
            amount={amount.amount}
            reason={amount.reason}
          />
        )
      })}
    </List>
  )
}

export default AmountList
