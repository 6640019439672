import React, { useMemo } from 'react'
import AmountCard from 'src/components/AmountCard'
import { fetcher } from 'src/api'
import useSWR from 'swr'
import {
  Donation,
  SheetsBatchGetResponse,
  SheetsGetResponse,
} from 'src/interfaces'
import AmountList from 'src/components/AmountList'

const ranges = [
  'Rita Cusca!A2:C',
  'Vela Agricola!A2:C',
  'Rita BAC!A2:C',
  'David Promerica!A2:C',
  'Paco Davivienda!A2:C',
  'GOFUNDME!A2:C',
]

const params = new URLSearchParams(ranges.map((value) => ['ranges', value]))

function formatData(data: SheetsGetResponse, bank?: string): Donation[] {
  return data.values.map(([received_at, name, amount]) => {
    return {
      name,
      amount,
      received_at: new Date(received_at),
      bank,
    }
  })
}

function formatBatch(data: SheetsBatchGetResponse): Donation[] {
  return data.valueRanges.flatMap((valueRange, index) => {
    const formatForBank = formatData.bind(null, valueRange)
    switch (index) {
      case 0:
        return formatForBank('Cuscatlán')
      case 1:
        return formatForBank('Agrícola')
      case 2:
        return formatForBank('BAC')
      case 3:
        return formatForBank('Promérica')
      case 4:
        return formatForBank('Davivienda')
      case 5:
        return formatForBank('GoFundMe')
      default:
        return formatForBank()
    }
  })
}

const Donations: React.FC = () => {
  const { data } = useSWR<SheetsBatchGetResponse>(
    ['values:batchGet', params],
    fetcher,
  )
  const donations = useMemo(() => data && formatBatch(data), [data])
  return (
    <AmountCard
      amount={donations?.reduce((sum, val) => sum + val.amount, 0)}
      icon="/img/donations-icon-bw.svg"
      description="Recaudado"
    >
      <AmountList
        amounts={donations?.sort((a, b) => +b.received_at - +a.received_at)}
      />
    </AmountCard>
  )
}

export default Donations
