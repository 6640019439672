import Fab from '@material-ui/core/Fab'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Zoom from '@material-ui/core/Zoom'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import React from 'react'
import smoothScroll from 'src/smoothscroll'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: theme.spacing(4),
      right: theme.spacing(6),
    },
  }),
)

export default function ScrollTopButton() {
  const classes = useStyles()
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  })

  return (
    <Zoom in={trigger}>
      <div role="presentation" className={classes.root}>
        <Fab
          color="secondary"
          size="large"
          aria-label="regresar al inicio"
          component="a"
          href="#home"
          onClick={smoothScroll('home')}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </div>
    </Zoom>
  )
}
