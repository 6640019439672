import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import FileCopy from '@material-ui/icons/FileCopy'
import DoneAll from '@material-ui/icons/DoneAll'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import { useClipboard } from 'use-clipboard-copy'
import Zoom from '@material-ui/core/Zoom'

const useStyles = makeStyles((theme) =>
  createStyles({
    bankAccounts: {
      backgroundColor: theme.palette.primary.main,
      paddingTop: '3rem',
      paddingBottom: '3rem',
    },
    heading: {
      fontWeight: 'bold',
      fontSize: '2rem',
      color: 'white',
      [theme.breakpoints.up('sm')]: {
        fontSize: '3.5rem',
      },
    },
    headingContainer: {
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
      borderRadius: 10,
      paddingLeft: '2rem',
      paddingRight: '2rem',
      textAlign: 'center',
      display: 'inline-block',
    },
    cardHeading: {
      fontWeight: 'bold',
    },
  }),
)

interface BankCardProps {
  number: string
  bank: string
  holder: string
  type: string
  img: string
}

const CopyButton: React.FC<{ text: string; label: string }> = ({
  text,
  label,
}) => {
  const clipboard = useClipboard({ copiedTimeout: 1000 })
  const theme = useTheme()
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  }
  return (
    <div style={{ position: 'relative' }}>
      <Zoom
        key="waiting"
        in={!clipboard.copied}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${
            !clipboard.copied ? transitionDuration.exit : 0
          }ms`,
        }}
        unmountOnExit
      >
        <IconButton
          title={label}
          aria-label={label}
          style={{ position: 'absolute', top: -10, right: -10 }}
          onClick={() => clipboard.copy(text)}
        >
          <FileCopy fontSize="small" color="action" />
        </IconButton>
      </Zoom>
      <Zoom
        key="copied"
        in={clipboard.copied}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${
            clipboard.copied ? transitionDuration.exit : 0
          }ms`,
        }}
        unmountOnExit
      >
        <IconButton
          aria-label="Copiado con éxito"
          style={{ position: 'absolute', top: -10, right: -10 }}
          disabled
        >
          <DoneAll color="primary" />
        </IconButton>
      </Zoom>
    </div>
  )
}

const BankCard: React.FC<BankCardProps> = ({
  number,
  bank,
  holder,
  type,
  img,
}) => {
  return (
    <Card>
      <CardContent>
        <CardHeader
          avatar={<img style={{ width: 70 }} src={img} alt={bank} />}
          title={bank}
          subheader={type}
          style={{ padding: 0, marginBottom: '1rem' }}
        />
        <Grid container justify="space-between">
          <Grid item xs>
            <Typography color="textSecondary">Número de cuenta</Typography>
          </Grid>
          <Grid item xs={2}>
            <CopyButton text={number} label="Copiar número de cuenta" />
          </Grid>
        </Grid>
        <Typography gutterBottom variant="h6" component="p">
          {number}
        </Typography>
        <Grid container justify="space-between">
          <Grid item xs>
            <Typography color="textSecondary">Destinatario</Typography>
          </Grid>
          <Grid item xs={2}>
            <CopyButton text={holder} label="Copiar destinatario" />
          </Grid>
        </Grid>
        <Typography variant="h6" component="p">
          {holder}
        </Typography>
      </CardContent>
    </Card>
  )
}

const accounts = [
  {
    bank: 'Banco Promérica',
    number: '2000035005181',
    type: 'Cuenta corriente',
    holder: 'David Enrique Leal Jiménez',
    img: '/img/promerica.png',
  },
  {
    bank: 'BAC Credomatic',
    number: '115444481',
    type: 'Cuenta de ahorro',
    holder: 'Rita Alejandra Machuca Portillo',
    img: '/img/bac.png',
  },
  {
    bank: 'Banco Cuscatlán',
    number: '152401000161432',
    type: 'Cuenta de ahorro',
    holder: 'Rita Alejandra Machuca Portillo',
    img: '/img/cuscatlan.png',
  },
  {
    bank: 'Banco Agrícola',
    number: '3111370364',
    type: 'Cuenta corriente',
    holder: 'José Manuel Vela Jiménez',
    img: '/img/agricola.png',
  },
  {
    bank: 'Davivienda',
    number: '80540284993',
    type: 'Cuenta de ahorro',
    holder: 'Francisco Antonio Pinto Melgar',
    img: '/img/davivienda.png',
  },
]

const BankDetails: React.FC = () => {
  const classes = useStyles()
  return (
    <Grid
      container
      justify="center"
      className={classes.bankAccounts}
      spacing={6}
    >
      <Grid item xs={10}>
        <div className={classes.headingContainer}>
          <Typography className={classes.heading} component="h2">
            ¿Cómo ayudar?
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={10} container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <Card>
            <CardMedia>
              <img
                src="/img/gofundme.png"
                alt="dona en GoFundMe"
                style={{ width: '100%' }}
              />
            </CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h6" component="p">
                GoFundMe
              </Typography>
              <Typography>
                Si quieres donar con tarjeta, puedes hacerlo a través de
                GoFundMe.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                href="https://gf.me/u/x6p8xt"
                target="_blank"
                rel="noreferrer"
              >
                Ir al sitio
              </Button>
            </CardActions>
          </Card>
        </Grid>
        {accounts.map((account) => (
          <Grid key={account.number} item xs={12} sm={6} md={4} lg={3} xl={2}>
            <BankCard {...account} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default BankDetails
