import React from 'react'

const smoothScroll = (id: string) => (
  event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
) => {
  event.preventDefault()
  const selector = `#${id}`
  const anchor = (
    (event.target as HTMLDivElement).ownerDocument || document
  ).querySelector(selector)

  if (anchor) {
    setTimeout(() => {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
      window.history.pushState({}, '', selector)
    })
  }
}

export default smoothScroll
